@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}


/*
body {
  overflow: hidden;
}
*/

body {
  color: white;
  background-color: #6b7280;
}